export const baseKeys = {
  id: "id_canal",
  getId: "_id",
  channel: "canal",
  upperLevelChannel: "id_padre_directo",
  owner: "responsable",
  list: "canales",
  description: "canal_descripcion",
  code: "codigo",
};

export const paymentMethodsKeys = {
  mainKey: "tiempo_pago",
  free: "libre",
  unique: "pago_unico",
  annual: "anual",
  bannual: "bi_anual",
  code: "codigo",
  description: "descripcion",
  unlimitedRate: "cuota_libre",
  annualRate: "cuota_anual",
  bannualRate: "cuota_bi_anual",
};

export const companyKeys = {
  logo: "logo",
  filename: "nombre_archivo",
  name: "descripcion",
  code: "codigo",
  id: "id",
};

export const generalInfoKeys = {
  id: "_id",
  name: "informacion_general",
  sale: "venta",
  assistedSale: "venta_asistida",
  financial_part: "participa_financiera",
  contact: {
    key: "ubicacion_contacto",
    address: "direccion",
    address2: "direccion",
    person: "persona_contacto",
    personEmail: "persona_correo",
    phone: "telefono",
    home: "numero",
    floor: "piso",
    location: "comuna",
    locationDescription: "comuna_descripcion",
  },
  concessionaire: {
    key: "concesionario",
    documentNumber: "numero_documento",
    documentType: "tipo_documento",
    title: "razon_social",
  },
  country: {
    getKey: "pais",
    text: "pais_descripcion",
    value: "pais",
    key: "paises",
  },
  channelType: {
    getKey: "tipo_canal",
    text: "tipo_canal_descripcion",
    value: "id",
    key: "tipo_canales",
    code: "codigo",
    typeLevels: {
      0: "CANAL",
      1: "CONVENIO",
      2: "CONCESIONARIO",
      3: "SUCURSAL",
    },
  },
  company: {
    text: "descripcion",
    value: "id",
    picture: "logo",
    key: "companias",
    payment: "medios_pago",
    code: "codigo",
  },
  paymentMethod: {
    text: "medio_descripcion",
    value: "id_medio_de_pago",
    data: paymentMethodsKeys,
    key: "medios_de_pago",
    getKey: "medios_de_pago",
    code: "codigo",
  },
  manager: {
    key: "responsablesCanal",
    phone: "celular",
    firstName: "nombre",
    lastName: "primer_apellido",
    email: "correo_electronico",
  },
};

export const advanceInfoKeys = {
  signature: {
    id: "_id",
    key: "tipo_firma",
    getKey: "tipos_firmas",
    code: "codigo",
    description: "descripcion",
  },
  fees: {
    key: "cuotas_por",
    fees: "cuotas",
    productValidity: "vigencia_del_producto",
    fixedInstallments: "cuotas_fijas",
  },
  inspections: {
    key: "inspecciones",
    needed: "aplica_inspeccion",
    list: "inspecciones",
    companies: "companias",
    specific: "especifica_inspeccion_por_compania",
    manage: "gestiona_inspeccion_mclick",
    specificList: "inspecciones_por_compania",
    business: {
      key: "empresas",
      text: "empresa",
      value: "_id",
      id: "id_empresa",
    },
    type: {
      key: "tipo_inspecciones",
      text: "inspeccion",
      value: "_id",
      id: "id_inspeccion",
    },
  },
  discount: {
    key: "aplica_descuento",
  },
  documents: {
    key: "requiere_documento_venta",
  },
  policy: {
    key: "genera_poliza",
  },
  report: {
    key: "usuario_genera_informe_simulaciones",
  },
  channelType: {
    getKey: "tipo_canal",
    code: "codigo",
  },
};

export const brandModelsKeys = {
  key: "marcas_modelos",
  id: "_id",
  brandId: "id_marca",
  brandCode: "marca",
  brandDescription: "marca_descripcion",
  brandDisabled: "eliminado",
  modelsArray: "modelos",
  modelId: "id_modelo",
  modelCode: "modelo",
  modelDescription: "modelo_descripcion",
  modelBooleanDeductible: "aplica_filtro_deducibles",
  modelDeductible: "deducibles",
  modelDisabled: "eliminado",
};

export const advanceConfigKeys = {
  base: "configuracion_avanzada",
  form: {
    booleanBrandModel: "aplica_marcas_modelos",
    brandModel: "marcas_modelos",
    booleanInsuranceMateria: "aplica_materia_asegurada",
    insuranceMateria: "materias_aseguradas",
    booleanProductUse: "aplica_uso_vehiculos",
    productUse: "uso_vehiculos",
    // booleanZeroKm: "aplica_cero_kilometros",
    // zeroKm: "cero_kilometros",
    booleanYear: "aplica_anios",
    year: "anios",
    booleanVehicleStatus: "aplica_estado_vehiculo",
    vehicleStatus: "permite_estado_vehiculo",
    vehicleNew: "nuevo",
    vehicleUsed: "usado",
    booleanPrivacy: "aplica_aviso_privacidad",
    privacyValidity: "vigencia_aviso_privacidad",
    privacy: "avisos_de_privacidad",
  },
  brandModel: brandModelsKeys,
  insuranceMateria: {
    key: "materiales_asegurados",
    description: "descripcion",
  },
  productUse: {
    key: "tipo_de_uso",
    disabled: "eliminado",
    description: "uso_producto_descripcion",
  },
  year: {
    key: "anios",
    description: "anio",
  },
  deductible: {
    key: "deducibles",
    description: "deducible",
  },
  privacy: {
    message: "mensaje_aviso_privacidad",
    title: "titulo_aviso_privacidad",
    subtitle: "prefijo_aviso_privacidad",
    doc: "documento",
    doc_title: "archivo_descripcion",
    doc_type: "tipo_archivo",
    file: "archivo",
  },
};

export const productKeys = {
  id: "_id",
  name: "name",
  key1: "heredable",
  key2: "tarificable",
  key3: "visible",
  items: "items",
  company: companyKeys,
  getCompanies: "companias",
  companyId: "idCompania",
  companyIds: "id_companias",
  companyName: "nombreCompania",
  productId: "idProducto",
  productName: "nombreProducto",
  channelType: {
    getKey: "tipo_canal",
    code: "codigo",
  },
};

export default baseKeys;
