<template>
  <div>
    <v-card>
      <div class="d-flex pa-3">
        <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
        <v-btn
          color="primary"
          text
          class="mx-3"
          small
          fab
          :loading="loading"
          @click="getItems()"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>

        <!-- FILTROS -->
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="200"
          @input="handlerCloseMenu"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" outlined small fab v-bind="attrs" v-on="on">
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-text>
              <v-text-field
                v-model="filter.cotizacion"
                label="Nro Cotización"
                dense
              ></v-text-field>
              <v-text-field
                v-model="filter.transaccion"
                label="Nro Transacción"
                placeholder="FINEX..."
                dense
              ></v-text-field>
              <v-text-field
                v-model="filter.documento"
                label="Nro Documento"
                dense
              ></v-text-field>
              <v-text-field
                v-model="filter.intencion"
                label="Nro Intención"
                dense
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" text small @click="handlerResetFilters()">
                Borrar
              </v-btn>
              <v-btn color="primary" text small @click="handlerSetFilter()">
                Filtrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>

      <!-- TABLA DE DATOS -->
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1"
        :loading="loading"
        :items-per-page="pagination.limit"
        :page="pagination.page"
        loading-text="Cargando elementos del listado..."
        no-data-text="No hay elementos para mostrar"
        no-results-text="No se han encontrado operaciones para mostrar"
        :footer-props="{
          'items-per-page-text': 'mostrar',
          'items-per-page-all-text': 'Todos',
          'items-per-page-options': [5, 10, 15, 30, 50, 100],
        }"
        :server-items-length="pagination.objects"
        @pagination="handlerSetPagination($event)"
      >
        <template #item.id_transaccion="{ item }">
          <b>ID:</b> {{ item.id_transaccion }} <br />
          <b>intención:</b> {{ item.id_intencion }}
        </template>

        <template #item.cliente="{ item }">
          <b
            >{{ item.cliente.tipo_documento }}:
            {{ item.cliente.numero_documento }}</b
          ><br />
          {{
            `${item.cliente.nombre} ${item.cliente.primero_apellido} ${item.cliente.segundo_apellido}`
          }}
        </template>

        <template #item.vehiculo="{ item }">
          <small
            ><b>Marca</b>: {{ item.vehiculo.marca }}<br />
            <b>Modelo</b>: {{ item.vehiculo.modelo }}<br />
            <b>año</b>: {{ item.vehiculo.anio }}</small
          >
        </template>

        <template #item.creado_el="{ item }">
          {{ item.creado_el | formatoFecha }}
        </template>

        <template #item.actions="{ item }">
          <v-tooltip top color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                x-small
                text
                fab
                v-bind="attrs"
                v-on="on"
                @click="handlerCopyToClipboard(item.link_operacion)"
              >
                <v-icon>mdi-clipboard-text-outline</v-icon>
              </v-btn>
            </template>
            <p class="mb-0">Copiar enlace</p>
          </v-tooltip>

          <v-tooltip top color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                x-small
                text
                fab
                v-bind="attrs"
                v-on="on"
                @click="handlerOpenNewTab(item.link_operacion)"
              >
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </template>
            <p class="mb-0">Abrir enlace</p>
          </v-tooltip>

          <v-btn
            color="error"
            x-small
            text
            fab
            :loading="item.removing"
            @click="handlerDeleteItem(item)"
          >
            <v-icon small> mdi-delete-outline </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <confirm-modal ref="confirmDialog" />
  </div>
</template>

<script>
import OperationService from "@/services/manager/integrations/operation.service.js";
import dayjs from "dayjs";

import ConfirmModal from "@/components/Modal/ConfirmModal.vue";

const Service = new OperationService();

import SetPageMixin from "@/Mixins/SetPageMixin.js";
export default {
  name: "CrudIntregrationOperations",
  mixins: [SetPageMixin],
  components: {
    ConfirmModal,
  },
  filters: {
    formatoFecha(value) {
      return dayjs(value).format("DD-MM-YYYY HH:mm");
    },
  },
  data() {
    return {
      title: "Listado de Operaciones",
      items: [],
      headers: [
        {
          value: "numero_cotizacion",
          sortable: false,
          text: "Cotización",
        },
        {
          value: "id_transaccion",
          sortable: false,
          text: "Transacción",
        },
        {
          value: "cliente",
          sortable: true,
          text: "Cliente",
        },
        {
          value: "vehiculo",
          sortable: true,
          text: "Vehículo",
        },
        {
          value: "creado_el",
          sortable: true,
          text: "Fech. Creación",
        },
        {
          value: "actions",
          sortable: true,
          text: "",
          align: "right",
        },
      ],
      loading: false,
      menu: false,
      filter: {
        transaccion: "",
        cotizacion: "",
        documento: "",
        intencion: "",
      },
      pagination: {
        limit: 10,
        page: 1,
        pages: 1,
        objects: 0,
      },
    };
  },
  methods: {
    getItems() {
      this.loading = true;
      Service.get(this.pagination, this.filter)
        .then((response) => {
          this.items = response.result;

          const { pagination } = response;
          this.pagination.limit =
            typeof pagination?.limit === "string"
              ? parseInt(pagination?.limit, 10)
              : pagination?.limit;
          this.pagination.page =
            typeof pagination?.page === "string"
              ? parseInt(pagination?.page, 10)
              : pagination?.page;
          this.pagination.pages = pagination?.totalPages;
          this.pagination.objects = pagination?.totalDocs;
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handlerSetPagination(e) {
      this.pagination.limit = e.itemsPerPage;
      this.pagination.page = e.page;
      this.pagination.pages = e.pageCount;
      this.pagination.objects = e.itemsLength;

      this.getItems();
    },

    handlerCloseMenu(e) {
      if (!e) {
        this.handlerSetFilter();
      }
    },

    handlerSetFilter() {
      this.pagination.page = 1;
      this.getItems();
    },

    handlerResetFilters() {
      this.filter.transaccion = "";
      this.filter.cotizacion = "";
      this.filter.documento = "";
      this.filter.intencion = "";
      this.handlerSetFilter();
    },

    handlerCopyToClipboard(link) {
      const message = {
        active: true,
        right: true,
        top: true,
        color: "primary",
      };
      try {
        navigator.clipboard.writeText(link).then(() => {
          message.text = "El enlace se ha copiado al portapapeles!";
          this.$store.commit("setSnackbar", message);
        });
      } catch {
        message.color = "error";
        message.text = "Error al intentar copiar el link en el portapapeles.";
        this.$store.commit("setSnackbar", message);
      }
    },
    handlerOpenNewTab(link) {
      const message = {
        active: true,
        right: true,
        top: true,
        color: "primary",
      };
      try {
        message.text = "Abriendo enlace...";
        message.timeout = 2000;
        this.$store.commit("setSnackbar", message);
        setTimeout(() => {
          window.open(link, "_blank");
        }, 1000);
      } catch {
        message.color = "error";
        message.text = "Ha ocurrido un error al intentar abrir el enlace.";
        this.$store.commit("setSnackbar", message);
      }
    },

    handlerDeleteItem(item) {
      const selectedItem = item;
      this.$refs.confirmDialog
        .show({
          title: "Eliminar una operación",
          description: "¿Esta seguro de querer eliminar esta operación?",
        })
        .then((result) => {
          if (result) {
            selectedItem.removing = true;
            Service.delete(selectedItem.id)
              .then((response) => {
                this.$store.commit("setSnackbar", {
                  active: true,
                  text: response.message,
                  top: true,
                  right: true,
                  color: "info",
                });
                this.getItems();
              })
              .catch((error) => {
                this.$store.commit("setSnackbar", {
                  active: true,
                  text: error,
                  top: true,
                  right: true,
                  color: "error",
                });
              })
              .finally(() => {
                selectedItem.removing = false;
              });
          }
        });
    },
  },
};
</script>
