import { endpoints } from "@/helpers/endpoints.js";
import { bffHeaders } from "@/helpers/headers";
import axios from "axios";

class BitacoraService {
  constructor() {
    this.URL_API = endpoints.manager.bitacora;
    this.API_HEADER = bffHeaders;
  }

  getAllbitacora = async (page = 1, limit = 10) =>
    new Promise((resolve, reject) => {
      axios({
        url: `${this.URL_API}?page=${page}&limit=${limit}`,
        method: "GET",
        headers: this.API_HEADER,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

  getFileUrl = async (url) => {
    try {
      const respuesta = await axios.get(`${url}`);

      return respuesta;
    } catch (error) {
      return error.response;
    }
  };
}
export default BitacoraService;
