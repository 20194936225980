import Vue from "vue";
import VueRouter from "vue-router";

import Cruds from "./cruds";
import { routes as Manager } from "./manager";
import { routes as Integrations } from "./integrations";
import NotAuthorized from "@/views/Authentication/NotAuthorized";
import Loader from "@/views/Loader";
Vue.use(VueRouter);
const routes = [
  {
    path: "/recover-password",
    name: "Recover",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/views/Authentication/RecoverAccount.vue"
      ),
  },
  {
    path: "/new-password",
    name: "NewPassword",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/views/Authentication/InsertNewPassword.vue"
      ),
  },

  {
    path: "/login",
    name: "Login",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Authentication/Login.vue"
      ),
  },
  {
    path: "/not-authorized",
    name: "NotAuthorized",
    component: NotAuthorized,
  },

  {
    path: "/",
    component: Loader,
    meta: {
      requiresAuth: true,
    },
  },
  //----------------------------------------------------------------------------- EDER BEGIN
  {
    path: "/dashboard",
    component: () => import("@/layouts/dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      ...Manager,
      ...Cruds,
      ...Integrations,
      {
        path: "",
        name: "Home",
        component: () => import("@/views/Home/Home.vue"),
      },
      // Perfil de Usuario
      {
        path: "profile",
        name: "Profile",
        component: () => import("@/views/Profile/ConfigProfile.vue"),
        props: true,
      },
      // Caracteristicas del Canal
      {
        path: "caracteristicas-del-canal",
        name: "ChannelFeature",
        component: () => import("@/views/Channel/ChannelFeature.vue"),
      },
      // Personalizacion Corporativa
      {
        path: "personalizacion-corporativa",
        name: "Customization",
        component: () => import("@/views/Customization/index.vue"),
      },
      // Administracion de usuarios
      {
        path: "administracion-de-usuarios",
        name: "UserConfig",
        component: () => import("@/views/Users/index.vue"),
      },
    ],
  },
  {
    name: "Enconstruccion",
    path: "/enconstruccion",
    component: () => import("@/views/Processing.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = sessionStorage.getItem("user");
  // const token_temporal = sessionStorage.getItem("token_temporal");
  // console.log("token temporal: ", token_temporal);
  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    // next({ name: "Login" });
  }
  next();
});

export default router;
