import axios from "axios";
import { endpoints } from "@/helpers/endpoints.js";
import { bffHeaders } from "@/helpers/headers.js";

// METODOS DEL CANAL
export const REQUEST_CHANNELS = ({ commit, getters }, params = {}) => {
  // OBTENER TODOS LOS CANALES
  const path = endpoints.channels.get,
    messages = getters.GetChannelMessages;
  return new Promise((resolve, reject) => {
    axios({ method: "GET", url: path, data: params, headers: bffHeaders })
      //.get(path, { params }, {headers: bffHeaders})
      .then((r) => {
        const result = r.data;
        commit("SetChannels", result);
        commit("SetChannelOrder", true);
        resolve("ok");
      })
      .catch(() => reject(messages.getError));
  });
};

export const CREATE_CHANNEL = ({ dispatch, getters, rootGetters }, payload) => {
  // CREAR UN CANAL
  // payload => Datos del formulario de crear canal (Descripcion, Codigo e Id del canal de dependencia),
  const path = endpoints.channels.get,
    messages = getters.GetChannelMessages;
  return new Promise((resolve, reject) => {
    let form = {};
    form.canal_descripcion = payload.description;
    form.codigo = payload.code;
    if (payload.id) form.id_dependencia = payload.id;
    axios({ method: "POST", url: path, data: form, headers: bffHeaders })
      //.post(path, form)
      .then(async (response) => {
        await dispatch("REQUEST_CHANNELS");
        resolve({
          result: response.data,
          info: messages.save,
        });
      })
      .catch((r) => reject(rootGetters.GetError(r, messages.saveError)));
  });
};

export const EDIT_CHANNEL = ({ dispatch, getters, rootGetters }, payload) => {
  // payload => Datos del formulario editar canal (Descripcion, Codigo e Id del canal de dependencia) e Id del canal seleccionado,
  const path = `${endpoints.channels.get}/${payload.id}`,
    messages = getters.GetChannelMessages,
    formData = payload.formData;
  return new Promise((resolve, reject) => {
    let form = {};
    form.canal_descripcion = formData.description;
    form.codigo = formData.code;
    axios({ method: "PUT", url: path, data: form, headers: bffHeaders })
      //.put(path, form)
      .then(async () => {
        await dispatch("REQUEST_CHANNELS");
        resolve(messages.update);
      })
      .catch((r) => reject(rootGetters.GetError(r, messages.updateError)));
  });
};

export const DELETE_CHANNEL = ({ dispatch, getters }, payload) => {
  // Payload => Id del canal
  const path = `${endpoints.channels.get}/${payload}`,
    messages = getters.GetChannelMessages;
  return new Promise((resolve, reject) => {
    axios({ method: "DELETE", url: path, headers: bffHeaders })
      //.delete(path)
      .then(async () => {
        await dispatch("REQUEST_CHANNELS");
        resolve(messages.delete.success);
      })
      .catch(() => {
        let response = {
          message: "",
          type: "error",
        };
        response.message = messages.delete.error;
        reject(response);
      });
  });
};

export const REQUEST_CHANNEL_DOMAIN = ({ getters, commit }, payload) => {
  const path = `${endpoints.channels.get}/${payload}${endpoints.channels.domain}`,
    messages = getters.GetChannelDomainMessages,
    keys = getters.GetGeneralInfoKeys;
  // OBTENER EL DOMINIO DE UN CANAL
  // Payload => Id del canal seleccionado
  return new Promise((resolve, reject) => {
    axios({ method: "GET", url: path, headers: bffHeaders })
      //.get(path)
      .then((r) => {
        const result = r.data;
        // Guardar dominio en el store, en su defecto se guarda el dominio del canal padre
        commit(
          "SetDomain",
          result[keys.domain] && result[keys.domain] !== ""
            ? result[keys.domain] || result[keys.upperDomain] || ""
            : result[keys.upperDomain] || ""
        );
        resolve(result);
      })
      .catch(() => reject(messages.getError));
  });
};

export const REQUEST_ACCESS_URLS = ({ commit, getters }) => {
  const path = endpoints.channels.access,
    messages = getters.GetChannelDomainMessages;
  return new Promise((resolve, reject) => {
    axios({ method: "GET", url: path, headers: bffHeaders })
      //.get(path)
      .then((r) => {
        const result = r.data.data.result;
        commit("setAccessUrl", result);
        resolve(result);
      })
      .catch(() => reject(messages.getAccessUrlError));
  });
};

// === METODOS DE LA INFORMACION GENERAL DEL CANAL ===
export const REQUEST_CHANNEL_GENERAL_INFO = ({ commit, getters }) => {
  // OBTENER TODA LA INFORMACION GENERAL DEL CANAL
  const path = endpoints.generalInfo.get;
  const keys = getters.GetGeneralInfoKeys;
  const messages = getters.GetGeneralInfoMessages;
  return new Promise((resolve, reject) => {
    axios({ method: "GET", url: path, headers: bffHeaders })
      //.get(path)
      .then((r) => {
        const result = r.data.data.result;
        if (keys.country.key in result && result[keys.country.key].length > 0) {
          commit("SetCountries", result[keys.country.key]);
        }
        if (
          keys.channelType.key in result &&
          result[keys.channelType.key].length > 0
        ) {
          commit("SetChannelsType", result[keys.channelType.key]);
        }
        if (keys.company.key in result && result[keys.company.key].length > 0) {
          commit("SetCompanies", result[keys.company.key]);
        }
        if (
          keys.paymentMethod.key in result &&
          result[keys.paymentMethod.key].length > 0
        ) {
          commit("SetPaymentMethods", result[keys.paymentMethod.key]);
        }
        resolve(result);
      })
      .catch(() => reject(messages.getError));
  });
};

export const REQUEST_CHANNEL_GENERAL_INFO_DATA = (
  { commit, getters },
  payload
) => {
  // OBTENER TODA LA DATA GUARDADA DE LA INFORMACION GENERAL DEL CANAL
  // Payload => Id del canal seleccionado
  const path = `${endpoints.generalInfo.get}/${payload}`;
  const keys = getters.GetGeneralInfoKeys;
  const messages = getters.GetGeneralInfoMessages;
  return new Promise((resolve, reject) => {
    axios({ method: "GET", url: path, headers: bffHeaders })
      //.get(path)
      .then((response) => {
        const result = response.data.data.result;
        // Actualizar los metodos de pago
        commit("UpdatePaymentMethods", result[keys.paymentMethod.getKey]);

        resolve(response);
      })
      .catch(() => reject(messages.getDataError));
  });
};

export const SEND_CHANNEL_GENERAL_INFO = (
  { getters, rootGetters },
  payload
) => {
  const data = { ...payload.data };
  // ENVIAR TODA LA INFORMACION GENERAL DEL CANAL
  // Payload => data del formulario de informacion general y el Id del canal
  const url = `${endpoints.generalInfo.save}/${payload.channel}`;
  const messages = getters.GetGeneralInfoMessages;
  const keys = getters.GetBaseKeys;
  const generalKeys = getters.GetGeneralInfoKeys;
  // Condiciones
  data[keys.upperLevelChannel] = payload.upChannel;
  data[generalKeys.concessionaire.key][
    generalKeys.concessionaire.documentNumber
  ] = data[generalKeys.concessionaire.key][
    generalKeys.concessionaire.documentNumber
  ].replace(/[^A-Z0-9]/gi, "");
  return new Promise((resolve, reject) => {
    axios({ method: "PUT", url, data, headers: bffHeaders })
      .then(() => {
        // const { data } = await dispatch('REQUEST_CHANNEL_GENERAL_INFO_DATA', payload.channel) // Actualizar la data (opcional)
        resolve(messages.saved);
      })
      .catch((r) => reject(rootGetters.GetError(r, messages.saveError)));
  });
};

// === METODOS DE LA INFORMACION AVANZADA DEL CANAL ===
export const REQUEST_CHANNEL_ADVANCE_INFO = ({ commit, getters }) => {
  // OBTENER TODA LA INFORMACION AVANZADA DEL CANAL
  const path = endpoints.advanceInfo.get;
  const messages = getters.GetAdvanceInfoMessages;
  const keys = getters.GetAdvanceInfoKeys;
  return new Promise((resolve, reject) => {
    axios({ method: "GET", url: path, headers: bffHeaders })
      //.get(path)
      .then((r) => {
        const result = r.data;
        if (keys.signature.getKey in result) {
          commit("SetSignatureType", result[keys.signature.getKey]);
        }
        if (keys.inspections.key in result) {
          if (
            keys.inspections.business.key in result[keys.inspections.key] &&
            result[keys.inspections.key][keys.inspections.business.key].length >
            0
          ) {
            result[keys.inspections.key][keys.inspections.business.key].forEach(
              (element) => (element.disabled = false)
            );
            commit(
              "SetInspectionsBusiness",
              result[keys.inspections.key][keys.inspections.business.key]
            );
          }
          if (
            keys.inspections.type.key in result[keys.inspections.key] &&
            result[keys.inspections.key][keys.inspections.type.key].length > 0
          ) {
            commit(
              "SetInspectionsType",
              result[keys.inspections.key][keys.inspections.type.key]
            );
          }
        }
        resolve("ok");
      })
      .catch(() => reject(messages.getError));
  });
};

export const REQUEST_CHANNEL_ADVANCE_INFO_DATA = ({ getters }, payload) => {
  // OBTENER TODOS LOS DATOS ALMACENADOS DE LA INFORMAZION AVANZADA DEL CANAL
  // Payload => Id del canal seleccionado
  const path = `${endpoints.advanceInfo.get}/${payload}`;
  const messages = getters.GetAdvanceInfoMessages;
  return new Promise((resolve, reject) => {
    axios({ method: "GET", url: path, headers: bffHeaders })
      //.get(path)
      .then((r) => resolve(r))
      .catch(() => reject(messages.getDataError));
  });
};

export const SEND_CHANNEL_ADVANCE_INFO = ({ getters }, payload) => {
  // ENVIAR TODA LA INFORMACION AVANZADA DEL CANAL
  // Payload => data del formulario de informacion general y el Id del canal
  const path = `${endpoints.advanceInfo.save}/${payload.channel}`;
  const messages = getters.GetAdvanceInfoMessages;
  const keys = getters.GetAdvanceInfoKeys;
  // Condiciones de envio
  payload.data[keys.inspections.list].forEach((element, i) => {
    if (
      element[keys.inspections.business.id] === "" ||
      element[keys.inspections.type.id].length <= 0
    ) {
      payload.data[keys.inspections.list].splice(i, 1);
    }
  });
  return new Promise((resolve, reject) => {
    axios({ method: "PUT", url: path, data: payload.data, headers: bffHeaders })
      //.put(path, payload.data)
      .then(async () => {
        // const { data } = await dispatch('REQUEST_CHANNEL_ADVANCE_INFO_DATA', payload.channel) // Actualizar la data (opcional)
        resolve(messages.saved);
      })
      .catch(() => {
        reject(messages.saveError);
      });
  });
};

// === METODOS DE CONFIGURACION AVANZADA ===
export const REQUEST_CHANNEL_ADVANCE_CONFIG = ({ commit, getters }) => {
  // OBTENER TODA LA CONFIGURACION AVANZADA
  const url = `${endpoints.advanceConfig.base}`,
    messages = getters.GetAdvanceConfigMessages;
  // keys = getters.GetAdvanceInfoKeys;

  return new Promise((resolve, reject) => {
    axios({ method: "GET", url, headers: bffHeaders })
      .then((response) => {
        const result = response.data.data;
        commit("SetAdvanceConfig", result);
        resolve(result);
      })
      .catch(() => {
        reject(messages.getError);
      });
  });
};

export const REQUEST_CHANNEL_ADVANCE_CONFIG_DATA = ({ getters }, payload) => {
  // OBTENER TODOS LOS DATOS ALMACENADOS DE LA CONFIGURACION AVANZADA DEL CANAL
  // Payload => Id del canal seleccionado
  const url = endpoints.advanceConfig.baseById.replace(":id", payload),
    messages = getters.GetAdvanceConfigMessages;
    //keys = getters.GetAdvanceConfigKeys;
  return new Promise((resolve, reject) => {
    axios({ url, method: "GET", headers: bffHeaders })
      .then((response) => {
        const result = response.data ?? {};
        resolve(result);
      })
      .catch(() => reject(messages.getDataError));
  });
};

export const SEND_CHANNEL_ADVANCE_CONFIG = ({ getters }, payload) => {
  // ENVIAR TODA LA CONFIGURACION AVANZADA DEL CANAL
  // Payload => data del formulario de configuracion avanzada y el Id del canal
  const url = endpoints.advanceConfig.baseById.replace(":id", payload.channel),
    messages = getters.GetAdvanceConfigMessages;
  return new Promise((resolve, reject) => {
    axios({ url, method: "PUT", data: payload.data, headers: bffHeaders })
      .then(() => {
        resolve(messages.saved);
      })
      .catch(() => {
        reject(messages.saveError);
      });
  });
};

// === METODOS DE PRODUCTOS DEL CANAL ===
export const REQUEST_CHANNEL_PRODUCTS = ({ commit, getters }, payload) => {
  // OBTENER TODOS LOS PRODUCTOS DEL CANAL FILTRANDO CON IDS DE LAS COMPAÑIAS
  // Payload => Array con Ids de las compañias
  const url = `${endpoints.products.get}/${payload.id}`;
  const keys = getters.GetProductKeys;
  const messages = getters.GetProductMessages;
  // Condiciones
  let arrayOfCompanies = {};
  arrayOfCompanies[keys.companyIds] = payload?.companies ?? [];
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url,
      data: arrayOfCompanies,
      headers: bffHeaders,
    })
      .then((r) => {
        const result = r.data.data?.result ?? [];
        commit("SetProducts", result);
        resolve("ok");
      })
      .catch(() => reject(messages.getError));
  });
};

export const REQUEST_CHANNEL_PRODUCTS_DATA = ({ getters }, payload) => {
  // OBTENER LA DATA DE LOS PRODUCTOS DE UN CANAL EN ESPECIFICO
  // Payload => Id del canal
  const path = `${endpoints.channel.get}${payload}${endpoints.channel.products}`;
  const messages = getters.GetProductMessages;
  return new Promise((resolve, reject) => {
    axios({ method: "GET", url: path, headers: bffHeaders })
      //.get(path)
      .then((r) => resolve(r))
      .catch(() => reject(messages.getDataError));
  });
};

export const SEND_CHANNEL_PRODUCTS = ({ getters }, payload) => {
  // GUARDAR O ACTUALIZAR EL LISTADO DE PRODUCTOS DE UN CANAL
  // Payload => Array de productos con condiciones y Id del canal
  const path = endpoints.products.save;
  const messages = getters.GetProductMessages;
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      url: `${path}/${payload.channel}`,
      data: payload.data.products,
      headers: bffHeaders,
    })
      //.put(`${path}/${payload.channel}`, payload.data.products)
      .then(() => resolve(messages.saved))
      .catch(() => reject(messages.saveError));
  });
};

export const REQUEST_CHANNEL_COMPANIES = (
  { getters, dispatch, commit },
  payload
) => {
  // OBTENER TODAS LAS COMPANIAS DE UN CANAL
  // Payload => Id del canal
  const path = `${endpoints.channel.get}${payload}${endpoints.channel.company}`;
  const messages = getters.GetCompanyMessages;
  const keys = getters.GetCompanyKeys;
  return new Promise((resolve, reject) => {
    axios({ method: "GET", url: path, headers: bffHeaders })
      //.get(path)
      .then((r) => {
        const result = r.data.data.result;
        result.forEach(async (element) => {
          const { data } = await dispatch("REQUEST_FILE", element[keys.logo], {
            root: true,
          });
          element[keys.logo] = data.url;
        });
        commit("SetCompanies", result);
        resolve("ok");
      })
      .catch(() => {
        reject(messages.getError);
      });
  });
};

// METODOS DE USUARIOS DEL CANAL
export const REQUEST_CHANNEL_USERS = ({ commit, getters }, payload) => {
  // OBTENER TODOS LOS USUARIOS DE UN CANAL EN ESPECIFICO
  // Payload => String con el Id del Canal
  const path = `${endpoints.channel.get}${payload}${endpoints.channel.users}`;
  const messages = getters.GetUserMessages;
  return new Promise((resolve, reject) => {
    axios({ method: "GET", url: path, headers: bffHeaders })
      //.get(path)
      .then((r) => {
        commit("SetUsers", r.data.data.result.usuarios);
        resolve("ok");
      })
      .catch(() => reject(messages.getError));
  });
};

export const REQUEST_FINANCIALS = () => {
  // OBTENER TODOS LOS CANALES
  const path = endpoints.channel.financial;
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url: path,
      headers: bffHeaders,
    })
      .then((r) => {
        const result = r.data.data.result;
        resolve(result);
      })
      .catch((e) => reject("Error obteniendo las financieras", e));
  });
};
