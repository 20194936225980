const API_BFF_PATH = process.env.VUE_APP_BFF_NEGOCIO,
  getPathKey = "get-all",
  savePathKey = "save",
  channelsPathKey = "canales",
  domainParameterKey = "/parametros/dominio";

export const endpoints = {
  //base: API_PATH,
  baseBff: API_BFF_PATH,
  keys: {
    getAll: getPathKey,
    save: savePathKey,
    channels: channelsPathKey,
  },
  general: {
    countries: `/paises`,
  },
  channels: {
    get: `/canales`,
    domain: "/dominio",
    access: `/accesos`,
  },
  generalInfo: {
    get: `/personalizacion/get-all/informacion-general`,
    save: `/personalizacion/save/informacion-general`,
  },
  advanceInfo: {
    get: `/personalizacion/get-all/informacion-avanzada`,
    save: `/personalizacion/save/informacion-avanzada`,
  },
  advanceConfig: {
    base: `/${channelsPathKey}/configuracion-avanzada`,
    baseById: `/${channelsPathKey}/:id/configuracion-avanzada`,
  },
  products: {
    get: `/personalizacion/get-all/compania-productos/canal`,
    save: `/personalizacion/save/productos`,
  },
  channel: {
    g: `/personalizacion/get-all/`,
    get: `/personalizacion/get-all/canal/`,
    user: "/usuario",
    users: "/usuarios",
    company: "/companias",
    products: "/productos",
    financial: `/financieras`,
  },
  users: `/personalizacion/get-all/usuarios`,
  // roles_permisos: `/roles`,
  userRoles: `/usuario-roles/identificacion-usuario`,
  rolesIds: `/roles`,
  negociosGetAll: `/personalizacion/get-all`,
  customization: {
    get: `/personalizacion/get-all/personalizacion-corporativa`,
    save: `/personalizacion/save/personalizacion-corporativa/`,
    info: `/personalizacion/get-all/informacion-personalizacion`,
  },
  integrations: {
    operaciones: `/integraciones/forum/operaciones`,
    homologaciones: `/homologaciones`,
    convenio: `/convenios/listar`,
    sucursal: `/convenios/canales/:id/sucursales`,
  },
  cruds: {
    restriction: `/restricciones`,
    tasasCambio: `/tasas-cambios`,
    accesoPantallas: `/accesos-pantallas`,
    concesionariosTabla: `/concesionarios/detalle`,
    concesionarios: `/concesionarios`,
    sucursales: `/sucursales`,
    vendedores: `/vendedores`,
    comunas: `/localidades/comunas`,
    inspeccionEmpresa: `/inspecciones/empresas`,
    bancos: `/bancos`,
    mediosPago: `/medios-pago`,
    tipoPago: `/tipo-pagos`,
    tipoMedioPago: `/parametrizacion/dominio/tipo_medio_pago`,
    tipoCanales: `/tipo-canales`,
    tipoInspecciones: `/tipo-inspeccion`,
    tipoTarjetas: `/tipos-tarjetas`,
    tipoDocumentos: `/documentos`,
    roles: `/roles`,
    modulos: `/modulos`,
    permisos: `/permisos`,
    parametrizacion: `/parametrizacion`,
    personas: `/personas`,
  },
  manager: {
    convenios: `/convenios`,
    informes: {
      simulacionesForum: `/informes/cotizaciones/forum`,
      simulacionesMclick: `/informes/cotizaciones/mclick`,
      avisoPrivacidad: `/informes/politicas-privacidad`,
    },
    notificaciones: {
      base: `/notificaciones`,
      plantillas: `/plantillas`,
    },
    client: `/clients`,
    bitacora: `/bitacoras`,
    parametros: {
      base: `/parametros`,
      dominio: {
        notificacionesMetodoNotificacion: `${domainParameterKey}/notificaciones-metodo-notificacion`,
        notificacionesEstadoPlantilla: `${domainParameterKey}/notificaciones-estado-plantilla`,
        notificacionesConvenios: `${domainParameterKey}/notificaciones-convenios`,
        notificacionesProcesos: `${domainParameterKey}/notificaciones-procesos`,
        notificacionesOperaciones: `${domainParameterKey}/notificaciones-operaciones`,
        notificacionesEstadoNotificacion: `${domainParameterKey}/notificaciones-estado-notificacion`,
        notificacionesTiposEnvios: `${domainParameterKey}/notificaciones-tipos-envios`,
      },
    },
    permisos: `/permisos`,
    servicios: `/servicios`,
    aplicaciones: `/aplicaciones`,
    endpoints: `/endpoints`,
    roles: `/roles`,
    usuarios: `/usuarios`,
    bitacoras: `/bitacora-eventos`,
    cotizaciones: `/cotizaciones`,
    propuestas: `/propuestas`,
    territorios: {
      base: `/territorios`,
      byCode: `/territorios/pais/codigo`,
      paises: `/paises`,
      state: `/territorios/region`,
      district: `/territorios/provincia`,
      city: `/territorios/comuna`,
    },
  },
};

export default endpoints;
