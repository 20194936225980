<template>
  <div>
    <v-card>
      <v-card-title>
        <v-text-field v-model="search" v-bind="bind.search"></v-text-field>

        <v-divider v-bind="bind.divider"></v-divider>

        <v-spacer></v-spacer>
        <v-btn v-bind="bind.refresh" v-on="on.refresh">
          <v-icon>{{ icons.refresh }}</v-icon>
        </v-btn>
        <v-btn v-bind="bind.new" v-on="on.new"> {{ labels.new }} </v-btn>
      </v-card-title>

      <!-- TABLA DE DATOS -->
      <v-data-table v-bind="bind.table" :search="search">
        <template #item.actions="{ item }">
          <v-btn color="primary" fab text x-small @click="editItem(item)">
            <v-icon small> {{ icons.edit }} </v-icon>
          </v-btn>
          <v-btn color="error" x-small fab text @click="deleteItem(item)">
            <v-icon small>{{ icons.delete }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <!-- /TABLA DE DATOS -->
    </v-card>

    <!-- MODAL FORMULARIO -->
    <v-dialog
      v-model="dialog"
      scrollable
      :persistent="loadingForm"
      max-width="500px"
      @click:outside="resetForm()"
      @keydown.esc="resetForm()"
    >
      <validation-observer ref="observer">
        <v-form ref="form">
          <v-card>
            <v-card-title primary-title>
              Formulario de Acceso Pantallas
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                x-small
                @click="resetForm()"
                fab
                text
                :disabled="loadingForm"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="form.nombre"
                label="Nombre"
                dense
                outlined
                persistent-hint
                :rules="rules"
              ></v-text-field>
              <v-text-field
                v-model="form.codigo"
                label="Código"
                dense
                outlined
                persistent-hint
                :rules="rules"
                @keyup="
                  form.codigo = form.codigo.replace(' ', '_').toUpperCase()
                "
              ></v-text-field>
              <v-text-field
                v-model="form.seccion_nombre"
                label="Sección"
                dense
                outlined
                persistent-hint
                :rules="rules"
              ></v-text-field>
              <v-text-field
                v-model="form.seccion"
                label="Código Sección"
                dense
                outlined
                persistent-hint
                :rules="rules"
                @keyup="
                  form.seccion = form.seccion.replace(' ', '_').toUpperCase()
                "
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="saveItem()"
                :loading="loadingForm"
                >Guardar</v-btn
                >
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
    </v-dialog>
    <!-- /MODAL FORMULARIO -->

    <confirm-modal ref="confirmDialog" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PageMixin from "@/Mixins/SetPageMixin.js";
export default {
  name: "CrudRestrictionPage",
  mixins: [PageMixin],
  data() {
    return {
      editMode: false,
      headers: [
        {
          text: "Nombre",
          align: "start",
          filterable: true,
          sortable: true,
          value: "nombre",
        },
        {
          text: "Sección",
          align: "start",
          filterable: true,
          sortable: true,
          value: "seccion_nombre",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          filterable: false,
          align: "right",
        },
      ],
      form: {
        nombre: "",
        codigo: "",
        seccion: "",
        seccion_nombre: "",
      },
      rules: [
        (v) => !!v || "Este campo es obligatorio.",
      ],
    };
  },
  computed: {
    ...mapGetters("AccesoPantallas", ["Items"]),
    items() {
      return this.Items;
    },
    Objects: () => [
      {
        text: "Tarjeta de Crédito",
        value: "TC",
      },
    ],
    ListType: () => [
      {
        text: "Lista negra",
        value: "deny",
      },
      {
        text: "Lista blanca",
        value: "allow",
      },
    ],
  },
  methods: {
    ...mapActions("AccesoPantallas", ["GET", "POST", "PUT", "DELETE"]),

    setup() {
      this.getItems();
    },

    getItems() {
      this.loading = true;
      const params = {};
      this.GET(params)
        .catch((error) => {
          this.snackbar(error);
        })
        .finally(() => (this.loading = false));
    },

    saveItem() {
      this.loadingForm = true;
      if (this.$refs.form.validate()) {
        if (this.editMode) {
          this.PUT(this.form)
            .then((response) => {
              this.snackbar(response.info, "success");
              this.resetForm();
              this.getItems();
            })
            .catch((error) => {
              this.snackbar(error);
            })
            .finally(() => {
              this.loadingForm = false;
              this.editMode = false;
            });
        } else {
          this.POST(this.form)
            .then((response) => {
              this.snackbar(response.info, "success");
              this.resetForm();
              this.getItems();
            })
            .catch((error) => {
              this.snackbar(error);
            })
            .finally(() => (this.loadingForm = false));
        }
      }
    },

    editItem(item) {
      this.dialog = true;
      this.form.nombre = item.nombre;
      this.form.codigo = item.codigo;
      this.form.seccion = item.seccion;
      this.form.seccion_nombre = item.seccion_nombre;
      this.form.id = item._id;
      this.editMode = true;
    },

    deleteItem(item) {
      this.$refs.confirmDialog
        .show({
          title: "Eliminar una restricción",
          description: "¿Esta seguro de querer eliminar esta restricción?",
        })
        .then((result) => {
          if (result) {
            this.DELETE(item._id)
              .then((response) => {
                this.snackbar(response.info, "info");
                this.getItems();
              })
              .catch((error) => {
                this.snackbar(error);
              });
          }
        });
    },

    resetForm() {
      this.form.nombre = "";
      this.form.codigo = "";
      this.form.seccion = "";
      this.form.seccion_nombre = "";
      this.dialog = false;
      this.$refs.form.resetValidation();
    },
  },
};
</script>
